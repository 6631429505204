import React, { useState, useEffect } from "react";
import { CTX } from "../components/Store";
import { valDt, valDt2 } from './util';
import { v4 as uuidv4 } from 'uuid';
import 'bootstrap/dist/css/bootstrap.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './SunMoon.css';

export default function SunMoon() {
  const { st, sendReq } = React.useContext(CTX);
  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), [])
  const NOT_FOUND = -999;

  const eform = React.createRef(null);
  const bdate = React.createRef(null);
  const btime = React.createRef(null);
  const [city, setCity] = useState("");
  const [stctry, setStctry] = useState("");
  const [lmt, setLmt] = useState("");
  const [jcal, setJcal] = useState("");
  const [blong, setBlong] = useState("");
  const [blat, setBlat] = useState("");
  const [gmtoff, setGmtoff] = useState("");
  const [message, setMessage] = useState("");

  const [n_moon_phase, set_n_moon_phase] = useState(null);
  const [n_moon_interp, set_n_moon_interp] = useState("");
  const [n_moon_summ, set_n_moon_summ] = useState("");
  const [p_moon_phase, set_p_moon_phase] = useState(null);
  const [p_moon_interp, set_p_moon_interp] = useState("");
  const [p_moon_summ, set_p_moon_summ] = useState("");
  const [n_moon_title, set_n_moon_title] = useState("");
  const [p_moon_title, set_p_moon_title] = useState("");
  const [n_sun_interp, set_n_sun_interp] = useState("");
  const [n_sun_summ, set_n_sun_summ] = useState("");
  const [p_sun_interp, set_p_sun_interp] = useState("");
  const [p_sun_summ, set_p_sun_summ] = useState("");
  const [n_sun_title, set_n_sun_title] = useState("");
  const [p_sun_title, set_p_sun_title] = useState("");
  const [seltab, setSeltab] = useState('');

  useEffect(() => {
    set_n_moon_phase(null);
    set_n_moon_interp("");
    set_n_moon_summ("");
    set_p_moon_phase(null);
    set_p_moon_interp("");
    set_p_moon_summ("");
    set_n_sun_interp("");
    set_n_sun_summ("");
    set_p_sun_interp("");
    set_p_sun_summ("");
    set_n_moon_title('');
    set_p_moon_title('');
    set_n_sun_title('');
    set_p_sun_title('');
    setSeltab('');
    setMessage('');

    const req = "mpinit";
    sendReq({ msg: req });

    return() => {
      set_n_moon_phase(null);
      set_n_moon_interp("");
      set_n_moon_summ("");
      set_p_moon_phase(null);
      set_p_moon_interp("");
      set_p_moon_summ("");
      set_n_sun_interp("");
      set_n_sun_summ("");
      set_p_sun_interp("");
      set_p_sun_summ("");
      set_n_moon_title('');
      set_p_moon_title('');
      set_n_sun_title('');
      set_p_sun_title('');
      setSeltab('');
      setMessage('');
      st.lltzone={};
    };
    forceUpdateComponent();
  }, []);

  useEffect(() => {
    const NOT_FOUND = -999;
    set_n_moon_phase(null);
    set_n_moon_interp("");
    set_n_moon_summ("");
    set_p_moon_phase(null);
    set_p_moon_interp("");
    set_p_moon_summ("");
    set_n_sun_interp("");
    set_n_sun_summ("");
    set_p_sun_interp("");
    set_p_sun_summ("");
    set_n_moon_title('');
    set_p_moon_title('');
    set_n_sun_title('');
    set_p_sun_title('');
    setSeltab('');
    
    if( Object.keys(st.lltzone).length === 0 && st.lltzone.constructor === Object) {
    } else {
      let lng = st.lltzone.lng;
      let lat = st.lltzone.lat;
      let gmt_off = fmtTz(st.lltzone.tz);
      let msg = '';
      if (lng === NOT_FOUND) {
          lng = 0.0;
          lat = 0.0;
          gmt_off = 0.0;
          msg = 'Location not found';
          st.lltzone = {};
        } else {
          if (gmt_off === NOT_FOUND) {
            msg = 'GMT Offset not found. LMT?';
          }
        }
        setBlong(lng);
        setBlat(lat);
        setGmtoff(gmt_off);
        setMessage(msg);
        st.lltzone = {};
      }
    }, [st.lltzone]);

  useEffect(() => {
    if (st.n_moon_phase) { 
      let natal28 = st.n_moon_phase.substring(1);
      let prog28 = st.p_moon_phase.substring(1);
      setSeltab("tab1");
      set_n_moon_title("Natal Moon Phase " + natal28);
      set_p_moon_title("Progressed Moon Phase " + prog28);
      set_n_moon_interp(st.n_moon_interp);
      set_n_moon_summ(st.n_moon_summ);
      set_p_moon_phase(st.p_moon_phase);
      set_p_moon_interp(st.p_moon_interp);
      set_p_moon_summ(st.p_moon_summ);
      set_n_sun_interp(st.n_sun_interp);
      set_n_sun_summ(st.n_sun_summ);
      set_n_sun_title(st.n_sun_title);
      set_p_sun_interp(st.p_sun_interp);
      set_p_sun_summ(st.p_sun_summ);
      set_p_sun_title(st.p_sun_title);
    } else {
      set_n_moon_phase(null);
      set_n_moon_interp("");
      set_n_moon_summ("");
      set_p_moon_phase(null);
      set_p_moon_interp("");
      set_p_moon_summ("");
      set_n_sun_interp("");
      set_n_sun_summ("");
      set_p_sun_interp("");
      set_p_sun_summ("");
      set_n_moon_title('');
      set_p_moon_title('');
      set_n_sun_title('');
      set_p_sun_title('');
      setSeltab('');
      setMessage('');
      }
    }, [st.n_moon_phase]);

  const fmtDttm = (bdt, btm) => {
    let dt = valDt2(bdt + 'T' + btm);
    if (dt === '') {
      return '';
    }
    const tm = btm.split(':');
    let tmhr = parseInt(tm[0]);
    let ampm = 'AM';
    if (tmhr > 12) {
      tmhr -= 12;
      ampm = 'PM';
    }
    return(`${dt} ${tmhr}:${tm[1]}:00 ${ampm}`);
  }

  const fmtDttm2 = (bdt, btm) => {
    let dt = valDt2(bdt + 'T' + btm);
    if (dt === '') {
      return '';
    }
    const tm = btm.split(':');
    return(`${dt} ${tm[0]}:${tm[1]}:00`);
  }

  const fmtDttm3 = (bdt, btm) => {
    let dt = valDt(bdt + 'T' + btm);
    if (dt === '') {
      return '';
    }
    const tm = btm.split(':');
    let tmhr = parseInt(tm[0]);
    let ampm = 'AM';
    if (tmhr > 12) {
      tmhr -= 12;
      ampm = 'PM';
    }
    return(`${dt} ${tmhr}:${tm[1]}:00 ${ampm}`);
  }
  const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  const fmtTz = (tz) => {
    let tz2 = tz.toString();
    if (tz2.indexOf(".") === -1) {
      tz2 = tz2 + '.0';
    }
    return parseFloat(tz2);
  }

  const onBlur = (e) => {
    e.preventDefault();

    if ((e.target.name === 'stctry' ||
      e.target.name === 'city' ||
      e.target.name === 'bdate' ||
      e.target.name === 'btime' ) &&
      (stctry.length > 0 &&  city.length > 0 && bdate.current.value.length > 0 &&  btime.current.value.length > 0 )) {
      const dttm = fmtDttm3(bdate.current.value, btime.current.value);
      if (dttm === ''){
        setMessage('Invalid Date');
        return;
      }
      const cityst = `${city} ${stctry}`;
      const req = `"{"event":"lltzone-req","data":" { 'cityst': '${cityst}', 'dttm': '${dttm}'}"}"`;
      sendReq({ msg: req });
      setMessage('');
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
 
    let gmtos = NOT_FOUND;
    if (gmtoff === NOT_FOUND) {
      if (lmt === true)  {
        gmtos = blong / 15.0;
      }
    } else {
      gmtos = parseFloat(gmtoff);
    }
    if (blat === NOT_FOUND || blong === NOT_FOUND || gmtos === NOT_FOUND) {
      let msg = (gmtos === NOT_FOUND) ? 'GMT Offset not found. LMT?' : 'Location not found';
      setMessage(msg);
      return;
    }

    const dttm = fmtDttm2(bdate.current.value, btime.current.value);
    if (dttm === ''){
      setMessage('Invalid Date');
      return;
    }

    setJcal(false);
    let cal = false;

    let lsReg = localStorage.getItem('sgaRegister');

    const req = `"{"event":"sunmoon-req","data":" { 'dttm': '${dttm}', 'timezone': ${gmtos}, 'longitude': ${blong}, 'latitude': ${blat}, 'city': '${titleCase(city)}', 'state': '${stctry.toUpperCase()}', 'author': '${lsReg}', 'sex': 'M', 'jcal': ${cal} }"}"`;

    sendReq({ msg: req });
    setMessage('');
  }

  const onLMTChange = (e) => {
    setLmt(e.target.checked);
    if ( e.target.checked ===  true &&
      blong !== 0){
        setGmtoff(blong / 15.0 );
      }
  }

  const onJCALChange = (e) => {
    setJcal(e.target.checked);
  }

  const onCityChange = (val) => {
    setCity(val);
  }

  const onStCtryChange = (val) => {
    setStctry(val);
  }

  const onBlongChange = (val) => {
    setBlong(val);
  }

  const onBlatChange = (val) => {
    setBlat(val);
  }

  const onGmtoffChange = (val) => {
    setGmtoff(val);
  }

  const onTabClick = (e) => {
    setSeltab(e.currentTarget.id);
  }

  return (
   <>
   <h1 style={{textAlign: 'center'}}>Sun & Moon Phase Lookup</h1>
   <div style={{ margin: '5px', fontWeight: 'bold' }}>
   <Form ref={eform} onSubmit={submitForm}>

     <Row className="mb-3">
       <Form.Group as={Col} style={{ gap: "2px" }} controlId="formBirthDate">
         <Form.Label>Birth Date</Form.Label>
         <Form.Control type="date" name='bdate' ref={bdate} onBlur={onBlur} placeholder="Birth Date" required />
       </Form.Group>
	  	    
       <Form.Group as={Col} controlId="formBirthTime">
         <Form.Label>Birth Time</Form.Label>
         <Form.Control type="time" name='btime' ref={btime} onBlur={onBlur} 
 	      placeholder="Birth Time" required />
       </Form.Group>
     </Row>
	  	  	  
     <Row className="mb-3">
       <Form.Group as={Col} style={{gap: "2px"}} controlId="formCity">
         <Form.Label>City</Form.Label>
         <Form.Control name='city' onChange={e => onCityChange(e.target.value)} 
             onBlur={onBlur} required maxLength="80"/>
       </Form.Group>

       <Form.Group as={Col} controlId="formState">
         <Form.Label>State</Form.Label>
         <Form.Control name='stctry' placeholder="Birth State"
            onChange={e => onStCtryChange(e.target.value)} 
            onBlur={onBlur} required maxLength="30" />
       </Form.Group>
     </Row>
	  						      
     <Row className="mb-3">
       <Form.Group as={Col} style={{gap: "2px"}} controlId="formLongitude">
         <Form.Label>Longitude</Form.Label>
         <Form.Control name='blong' onChange={e => onBlongChange(e.target.value)} 
	    placeholder="Longtitude" value={blong} required/>
       </Form.Group>

       <Form.Group as={Col} controlId="formLatitude">
         <Form.Label>Latitude</Form.Label>
         <Form.Control name='blat' onChange={e => onBlatChange(e.target.value)} 
            placeholder="Latitude" value={blat} required/>
       </Form.Group>
     </Row>
	  						      
     <Row className="mb-3">
       <Form.Group as={Col} style={{gap: "2px"}} controlId="formGMToff">
         <Form.Label>GMT</Form.Label>
         <Form.Control name='gmtoff' onChange={e => onGmtoffChange(e.target.value)} 
            value={gmtoff} required/>
       </Form.Group>

       <Form.Group as={Col} style={{gap: "2px", fontSize: "16px"}} controlId="formGender">
         <div className='nccontainer'>
	   <div>
	     <Form.Label for="lmt">LMT</Form.Label>&nbsp;
	     <input type="checkbox" name="lmt" id="lmt" onChange={onLMTChange}/>
	   </div>
           <div>
             <Form.Label for="jcal">JCAL</Form.Label>&nbsp;
             <input type="checkbox" name="jcal" id="jcal" onChange={onJCALChange}/>
           </div>
         </div>
       </Form.Group>
     </Row>

     <div style={{textAlign: 'center'}}>
  	<Button variant="primary" type="submit">Lookup</Button>
    </div>
    <div className="fRow ext-center">
       <span className='emsg'>{message}</span>
    </div>  
   </Form>
  </div>

   <div className="tabset">
   <>
    <input type="radio" name="tabset" id="tab1" aria-controls="birth_moon" checked={seltab === 'tab1'} onClick={onTabClick} />
    <label htmlFor="tab1">BIRTH SUN</label>
    <input type="radio" name="tabset" id="tab2" aria-controls="prog_moon" checked={seltab === 'tab2'} onClick={onTabClick} />
    <label htmlFor="tab2">PROG SUN</label>
    <input type="radio" name="tabset" id="tab3" aria-controls="birth_moon" checked={seltab === 'tab3'} onClick={onTabClick} />
    <label htmlFor="tab3">BIRTH MOON</label>
    <input type="radio" name="tabset" id="tab4" aria-controls="prog_moon" checked={seltab === "tab4"} onClick={onTabClick} />
    <label htmlFor="tab4">PROG MOON</label>
 
    <div className="tab-panels">
    <>
      <section id="birth_sun" className="tab-panel">
        <p className="smpinterp"><strong>Summary:&nbsp;</strong>{n_sun_summ}</p>
        <p className="mpinterp"><strong>{n_sun_title}</strong></p>
        <div key={uuidv4()}>
	  {n_sun_interp.split("~").map((item,index) => <p className='mpinterp' key={uuidv4()}>{item}</p>)}
        </div> 
      </section>
      <section id="prog_sun" className="tab-panel">
	<p className="smpinterp"><strong>Summary:&nbsp;</strong>{p_sun_summ}</p>
        <p className="mpinterp"><strong>{p_sun_title}</strong></p>
        <div key={uuidv4()}>
	  {p_sun_interp.split("~").map((item,index) => <p className='mpinterp' key={uuidv4()}>{item}</p>)}
        </div> 
      </section>
      <section id="birth_moon" className="tab-panel">
        <p className="smpinterp"><strong>Summary:&nbsp;</strong>{n_moon_summ}</p>
        <p className="mpinterp"><strong>{n_moon_title}</strong></p>
        <div key={uuidv4()}>
	  {n_moon_interp.split("~").map((item,index) => <p className='mpinterp' key={uuidv4()}>{item}</p>)}
        </div> 
      </section>
      <section id="prog_moon" className="tab-panel">
	<p className="smpinterp"><strong>Summary:&nbsp;</strong>{p_moon_summ}</p>
        <p className="mpinterp"><strong>{p_moon_title}</strong></p>
        <div key={uuidv4()}>
	  {p_moon_interp.split("~").map((item,index) => <p className='mpinterp' key={uuidv4()}>{item}</p>)}
        </div> 
      </section>
    </>
    </div>

   </> 
  </div>
   </>
  );
}
/*
 
    <fieldset id="chartInfo">
     <div className="fRow">
      <label htmlFor="bdate">Date </label>
  	  <input type="text" name="bdate"  ref={bdate}
     	    placeholder="Date" onBlur={onBlur} required/>
 	    <label htmlFor="btime">Time </label>
 	    <input type="time" name="btime"  ref={btime}
    	  placeholder="Time" onBlur={onBlur} required/>
     </div>
     <div className="fRow">
 	    <label htmlFor="city">City </label>
  	  <input type="text" name="city"
 	     placeholder="City" onChange={e => onCityChange(e.target.value)} 
            onBlur={onBlur} required maxLength="80"/>
      
    	<label htmlFor="stctry">State </label>
    	<input type="text" name="stctry"
      		placeholder="State/Country" 
      		onChange={e => onStCtryChange(e.target.value)} 
	      onBlur={onBlur} required maxLength="30" />
	   </div>
        <div className="fRow">
          <label htmlFor="blong">Longitude </label>
    	  <input type="text" name="blong"
        	onChange={e => onBlongChange(e.target.value)}
        	placeholder="Longtitude" value={blong} required/>
        <label htmlFor="blat">Latitude </label>
    	  <input type="text" name="blat"
        	onChange={e => onBlatChange(e.target.value)}
        	placeholder="Latitude" value={blat} required/>
        </div>

        <div className="fRow">
         <label htmlFor="gmtoff">GMT </label>
    	   <input type="text" name="gmtoff"
      		onChange={e => onGmtoffChange(e.target.value)}
              	value={gmtoff} required/>
        <label ></label>
         <input name="lmt" type="checkbox"
	        className='move-left' onChange={onLMTChange}/>
         <label  style={{width: "5%"}} htmlFor="lmt">LMT </label>
    
         <input name="jcal" type="checkbox"
      		className='move-left' onChange={onJCALChange}/>
         <label style={{width: "4%"}} htmlFor="jcal">JCal</label>        
        
    </div>
    </fieldset>
    <div className="fRow fBtn">
      <button className="cli-submit" type="submit">Submit</button>
    </div><br/>
    <div className="fRow ext-center">
      <span className='emsg'>{message}</span>
    </div>  
*/ 
